/* PrivacyPolicy.css */
.privacy-policy h2 {
  margin-bottom: 5px;
  font-weight: bold;
}

.privacy-policy p {
  margin-bottom: 30px;
}

.privacy-policy ul {
  margin-bottom: 30px;
}

.privacy-policy.main-content {
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .privacy-policy .main-content {
    padding: 0;
  }
}
