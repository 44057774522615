.sortitem {
  position: relative;
  cursor: move;
  margin: 21px 0;
  border-radius: 10px;
}
.gray {
  width: 50px;
  background-color: #cecece;
  border-radius: 10px 0 0 10px;
}
.gray .icon {
  top: 16px;
  left: 5px;
  position: absolute;
}
.close {
  background: #e5fffe;
  display: inline-block !important;
  width: 28px;
  height: 28px;
  top: 11px;
  right: 5px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  position: absolute;
}
.close .icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  position: absolute;
}
