.form-control {
  margin-bottom: 10px;
}
label {
  display: block;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.form-control input,
.form-control select {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid rgba(33, 33, 33, 0.14);
}
span.active svg {
  filter: invert(148%) sepia(0%) saturate(1000%) hue-rotate(86deg)
    brightness(118%) contrast(119%);
}
.form-control span.error {
  display: none;
  color: #f14668;
  font-size: 12px;
  padding: 5px 0;
  margin: 0;
}
.form-control--invalid input {
  border: 1px solid #f14668;
}
.form-control--invalid span.error {
  display: block;
}
.form-control input[type="text"]:focus,
.form-control input[type="password"]:focus {
  border: 1px solid rgba(78, 55, 182, 0.5);
}
.control.has-icons-left .icon,
.control.has-icons-right .icon {
  top: 5px;
  cursor: pointer;
  z-index: 900;
  pointer-events: all;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
