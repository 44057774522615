.excel-upload {
  width: 100%;
  border: 1px dashed #ccc;
  display: inline-block;
  padding: 10px;
  background-color: rgb(240, 240, 240);
}
.excelUpload {
  background-color: rgb(118, 138, 204);
  border: none;
  padding: 10px;
  width: 100%;
  color: white;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.btn-pick {
  background-color: rgb(118, 138, 204);
  border: none;
  padding: 10px;
  color: white;
  float: right;
}
.submitExcel {
  background-color: thistle;
  border: none;
  display: block;
  padding: 10px;
  color: #fff;
  font-size: 20px;
  margin-top: 10px;
}
