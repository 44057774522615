#map {
  height: 75vh;
}
#mapCreate {
  height: 70vh;
}
.delete {
  background-color: firebrick;
  margin-top: 10px;
}
.input-stop {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}
textarea {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
}
.form-control {
  width: 100%;
}
select.form-control {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
}
.container-stop {
  box-sizing: border-box;
  padding: 10px 7px 0 7px;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #c9c9c9;
  margin: 0 8px 0 2px;
}
.container-stop > .columns {
  margin-bottom: 0;
}
.snap {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
}
.snap-line {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
.snap-line-start {
  background-color: #eaf6ff;
  border: 1px solid #93acb4;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.snap-line-end {
  background-color: #eaf6ff;
  border: 1px solid #93acb4;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.snap-line-time {
  background-color: #eaf6ff;
  border: 1px solid #93acb4;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
