.image-upload.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-upload__preview {
  width: 100%;
  min-height: 300px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}
.image-upload__preview img {
  height: 100%;
  max-width: 100%;
}
.choose {
  background-color: #583fc9;
  padding: 8px 20px;
  color: #fff;
  border-radius: 3px;
  border: 1px solid rgba(78, 55, 182, 0.5);
  font-size: 1rem;
}
