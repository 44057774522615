.sidebar-container {
  width: 263px;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  z-index: 1000;
}
.sidebar {
  top: 0;
  left: 0;
  width: 280px;
  padding-top: 40px;
  background-color: #2b303b;
  height: 100vh;
  overflow-y: scroll;
}
.sidebar li a,
.sidebar li .link {
  color: #929aac;
  display: block;
  padding: 12px 20px;
  border-left: 4px solid transparent;
}
.sidebar li .link {
  cursor: pointer;
}
.sidebar li .link span {
  display: inline-block;
  vertical-align: top;
}
.sidebar li a:hover {
  color: #fff;
  display: block;
}
.sidebar li .link:hover {
  color: #fff;
}
.sidebar .logo {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}
.sidebar .logo img {
  width: 60%;
}
.sidebar li a.active {
  background-color: #003249;
  color: #fff;
  border-left: 4px solid rgb(118, 138, 204);
}
.sidebar li a svg {
  margin-right: 10px;
}
.sidebar li a span {
  display: inline-block;
  vertical-align: top;
  padding-top: 3px;
}

@media only screen and (max-width: 767px) {
  .sidebar-container {
    display: none;
  }
}
