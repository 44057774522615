@import "https://cdn.jsdelivr.net/npm/bulma@1.0.0/css/bulma.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
}
*:focus {
  outline: none;
}
h1 {
  font-size: 26px;
  margin-bottom: 40px;
  display: inline-block;
}
h2 {
  font-size: 18px;
  margin-bottom: 20px;
}
.title {
  color: #555;
}
.title-h1 {
  position: relative;
}
.right-h1 {
  position: absolute;
  right: 0;
  top: 0;
}
form input[type="text"],
form input[type="email"],
form input[type="password"],
form textarea {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid rgba(33, 33, 33, 0.14);
}
form input[type="text"]:focus,
form input[type="password"]:focus {
  border: 1px solid rgba(78, 55, 182, 0.5);
}
.error {
  color: red;
  margin: 10px 0;
}

.content-body {
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 20px 20px 0px;
  background-color: #f2f2f6;
}
.main-content {
  padding: 70px 0px 20px 280px;
}
.wrapper-login {
  background-image: url("front.jpg");
  background-position: center;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
/* Nav */
.nav {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: #fff;
  color: rgb(88, 88, 88);
  padding-left: 280px;
  padding-top: 12px;
  padding-right: 20px;
  text-align: right;
  box-shadow: #e9e9e9 0px 1px 10px 0px;
}

/** Sidebar */
.sidebar-login {
  min-height: 100vh;
  float: right;
  width: 496px;
  padding: 10px 30px;
  background-color: #fff;
}
.sidebar-login .logo {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 30px;
  display: block;
}
.logo-title {
  display: block;
  font-weight: 700;
  text-align: center;
}
.button.is-normal {
  background-color: #583fc9;
  color: #fff;
}
input::placeholder {
  color: #bbb;
}
.links-help a {
  display: inline-block;
  padding: 5px;
  margin: 0 auto;
  margin-top: 60px;
  border-bottom: 1px dashed #583fc9;
}

/* Sidebar Right */
.sidebar-right {
  position: fixed;
  top: 0;
  right: 0;
  width: 280px;
  height: 100vh;
  background-color: #2b303b;
  padding: 10px;
  z-index: 1;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.sidebar-right.false {
  right: -280px;
}
.sidebar-right.true {
  right: 0;
}
.sidebar-right li {
  display: block;
  padding: 10px;
}
.sidebar-right li img {
  width: 70%;
  display: block;
  margin: 0 auto;
}

/* POST */
.post {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  overflow: hidden;
}
.post h2 {
  margin-bottom: 10px;
}
.post img {
  width: 200px;
  display: block;
  float: left;
}
.post .content {
  color: #aaa;
  float: left;
  margin-left: 20px;
}
.actions {
  text-align: right;
  float: right;
}

/* USERS */
.user {
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 10px;
}
.user img {
  width: 30px;
  display: block;
  float: left;
  margin-right: 10px;
  border-radius: 100%;
}

.card-mibus {
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: none;
}

.no_auth {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(14, 15, 49, 1);
  z-index: 9999;
}
.no_auth h1 {
  color: #fff;
  font-size: 200px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  text-align: center;
}
.no_auth p {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  text-align: center;
}
.modal {
  z-index: 9999;
}

.deactivate {
  cursor: pointer;
}
.deactivate path {
  fill: #bbb !important;
}
.deactivate:hover path {
  fill: #686868 !important;
}
.marker-label {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: #485fc7;
  padding: 5px;
  border-radius: 5px;
  margin-top: -20px;
  z-index: 1;
}
.table {
  table-layout: fixed;
}
.table td,
.table th {
  word-wrap: break-word;
  padding: 10px 10px 0px 10px;
}

@media only screen and (max-width: 767px) {
  .content-body {
    padding: 20px;
  }
  .table {
    table-layout: auto;
  }
  .nav {
    padding-left: 10px;
  }
}
