#googleMap {
  height: 900px;
  width: 100%;
}
.route {
  background-color: #fff;
  padding: 20px;
  font-weight: bold;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.route span {
  float: right;
  font-weight: normal;
}

.input-form-control {
  width: 100%;
  padding: 15px 20px;
  border: 1px solid rgba(33, 33, 33, 0.14);
}