.upload {
  background-color: #fff;
  padding: 20px;
  font-weight: bold;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}
.upload span {
  float: right;
  font-weight: normal;
}
