.search {
  margin: 0;
}
.search input {
  width: 100%;
  display: inline-block;
}
.search.has-date input {
  width: 90%;
  float: right;
}
.react-datepicker-wrapper {
  width: 10% !important;
  float: left;
}
